import { Navigate } from "react-router-dom";
import { useUserContext } from "../Context/AuthContext";

export default function SigninProtected({ children }) {
  const { user } = useUserContext();
  if (user) {
    return <Navigate to="/"></Navigate>;
  }
  return children;
}

import React from "react";
import "./cards.css"; // Import your CSS file for styling
import Card2 from "../Card2/Card2";

const Cards = ({
  products,
  products2,
  ensemble,
  producttime,
  producttime2,
  products3,
  producttime3,
  products4,
  producttime4,
  indiamart,
  query,
}) => {
  producttime = producttime * 1000;
  producttime = Math.floor(producttime);
  producttime2 = producttime2 * 1000;
  producttime2 = Math.floor(producttime2);
  producttime3 = producttime3 * 1000;
  producttime3 = Math.floor(producttime3);

  return (
    <>
      <div className="container">
        <div className="both-cards">
          <div className="cards-container">
            <h2>ZSearch</h2>
            <h5 className={query ? "title" : "hidden-title"}>
              Results for {query}
            </h5>
            {products3.slice(0, 10).map((product, index) => (
              <Card2 key={index} product={product} />
            ))}
          </div>

	  {/*
          <div className="cards-container">
            <h2>GenSearch v3</h2>
            <h5 className={query ? "title" : "hidden-title"}>
              Results for {query}
            </h5>
            {products.slice(0, 10).map((product, index) => (
              <Card2 key={index} product={product} />
            ))}
          </div>
	  */}

          <div className="cards-container">
            <h2>IndiaMart</h2>
            <h5 className={query ? "title" : "hidden-title"}>
              Results for {query}
            </h5>
            {products2.slice(0, 10).map((product, index) => (
              <Card2 key={index} product={product} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Cards;

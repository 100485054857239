import React, { useState, useEffect } from "react";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import Cards from "./components/Cards/Cards";
import axios from "axios";
import LoadingSpinner from "./components/Loader/LoadSpinner";
import Signin from "./components/user/Signin";
import { useUserContext } from "./components/Context/AuthContext";
import { useNavigate } from "react-router-dom";

function App() {
  const { user } = useUserContext();
  const navigate = useNavigate();

  //Change these with useReducer
  const [cardData, setCardData] = useState([]);
  const [cardData2, setCardData2] = useState([]);
  const [cardData3, setCardData3] = useState([]);
  const [cardData4, setCardData4] = useState([]);
  const [cardData1time, setCardData1time] = useState(0);
  const [cardData2time, setCardData2time] = useState(0);
  const [cardData3time, setCardData3time] = useState(0);
  const [cardData4time, setCardData4time] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [ensemble, setEnsemble] = useState(false);
  const [indiamart, setIndiamart] = useState(false);
  const [Query, setQuery] = useState("");

  const handleGptClick = () => {
    setEnsemble(!ensemble);
  };
  const handleIndiamartClick = () => {
    setIndiamart(!indiamart);
  };

  const handleEnter = async (query) => {
    setIsLoading(true);
    setQuery(query);
    try {
      // const ipResponse = await axios.get("https://geolocation-db.com/json/");
      // const userIpAddress = ipResponse.data.IPv4;
  
      // const response1 = await axios.get("http://91.203.133.178:8087/gensearch_v3", {
      //   params:{
      //     'query' : query,
      //   },
      //   headers: {
      //     'accept': 'applicaton/json',
      //     'X-User-IP' : userIpAddress,
      //     'user': user.email
      //   }
      // });

      // setCardData(response1.data.results);
      // setCardData1time(response1.data.time); 
  
   

      // const response2 = await axios.get('http://91.203.133.178:8087/im_search_boosted', {
      const response2 = await axios.get('https://zsearch.knowdis.ai/api/im_search_boosted', {
      // const response2 = await axios.get('http://91.203.133.178:8086/gensearch_v2', {
          params: {
            'query': query,            
          },
          headers: {
            'accept': 'application/json',
            // 'X-User-IP': userIpAddress, 
            'X-User-IP': 'dummy', 
            'user': user.email
          }
        });

      setCardData2(response2.data.results);
      setCardData2time(response2.data.time);

      const response3 = await axios.get('https://zsearch.knowdis.ai/api/zsearch', {
      // const response3 = await axios.get('http://49.50.68.129:8082/im_search_nonboosted', {
          params: {
            'query': query,
          },
          headers: {
            'accept': 'application/json',
            // 'X-User-IP': userIpAddress, 
            'X-User-IP': 'dummy', 
            'user': user.email
          }
        });

      setCardData3(response3.data.results);
      setCardData3time(response3.data.time);
  
      // const response4 = await axios.get('http://91.203.133.178:8087/im_search_nonboosted', {
      //     params: {
      //       'query': query,            
      //     },
      //     headers: {
      //       'accept': 'application/json',
      //       'X-User-IP': userIpAddress, 
      //       'user': user.email
      //     }
      //   });

      // setCardData4(response4.data.results);
      // setCardData4time(response4.data.time);

 
      // Continue making other API calls with the IP address as a header
    } catch (error) {
      console.log("Error fetching data:", error.message);
    } finally {
      setIsLoading(false);
    }
  };
  
  

  return (
    <>
    {/* <IPAddress/> */}
      <Navbar
        handleEnter={handleEnter}
        handleGptClick={handleGptClick}
        handleIndiamartClick={handleIndiamartClick}
        ensemble={ensemble}
        indiamart={indiamart}
      ></Navbar>

      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Cards
          products={cardData}
          products2={cardData2}
          producttime={cardData1time}
          producttime2={cardData2time}
          producttime3={cardData3time}
          products3={cardData3}
          products4={cardData4}
          producttime4={cardData4time}
          ensemble={ensemble}
          indiamart={indiamart}
          query={Query}
        />
      )}
    </>
  );
}

export default App;

// Card.jsx
import React from "react";
import "./card2.css"; // Import your CSS file for styling

const Card2 = ({ product }) => {
  const renderProductISQS = () => {
    if (!product.product_isqs) return null;

    const isqsArray = product.product_isqs;

    return (
      <ul className="product-isqs2">
        {isqsArray.slice(0, 3).map((isq, index) => (
          <li key={index}>
            <span className="isq-name2">{isq.name}:</span> {isq.value}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="card2">
      <h2 className="product-title2">
          <a
            href={product.product_url}
            target="_blank"
            className="product-link2"
          >
            {product.product_name}
          </a>
      </h2>
      <div className="card-content2">
        <img
          src={product.product_image}
          alt="Product2"
          className="product-image2"
        />
        <div className="product-details2">
          {/* <a
            href={product.company_url}
            target="_blank"
            className="product-link2"
          > */}
            {product.company_name}
          {/* </a> */}
          <div className="product-price2">₹{product.product_price}</div>
          {renderProductISQS()}
        </div>
      </div>
    </div>
  );
};

export default Card2;

import "./spinner.css";
import { lineSpinner } from "ldrs";
lineSpinner.register();

export default function LoadingSpinner() {
  return (
    <div className="spinner">
      <l-line-spinner
        size="40"
        stroke="3"
        speed="1"
        color="black"
      ></l-line-spinner>
    </div>
  );
}

// Default values shown

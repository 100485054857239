import SignIn from "../components/user/Signin";
import { createBrowserRouter, Route, Routes } from "react-router-dom";
import ErrorPage from "../components/Common/ErrorPage";
import App from "../App";
import Error_credentials from "../components/Common/Error_credentials";
import ProtectedRoute from "../components/Common/ProtectedRoute";
import SigninProtected from "../components/Common/SigninProtected";
const routes = [
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <App />
      </ProtectedRoute>
    ),
  },
  {
    path: "/signin",
    element: (
      <SigninProtected>
        <SignIn />
      </SigninProtected>
    ),
  },
  {
    path: "/error",
    element: <ErrorPage />,
  },
  {
    path: "/invalid_credentials",
    element: <Error_credentials />,
  },
];

export const router = createBrowserRouter(routes);

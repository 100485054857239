// SearchBar.jsx
import React, { useState } from "react";
import "./searchBar.css"; // Import your CSS file for styling
import { IoIosSearch } from "react-icons/io";

const SearchBar = ({ handleEnter }) => {
  const [query, setQuery] = useState("");

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleEnter(query);
    }
  };
  const handleSubmit = () => {
    handleEnter(query);
  };

  return (
    <div className="search-bar-container">
      <input
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onKeyDown={handleKeyPress}
        placeholder="Search..."
        className="search-input"
      />
      <button className="search-button" onClick={handleSubmit}>
        Search
      </button>
    </div>
  );
};

export default SearchBar;

import { createContext, useEffect, useState } from "react";
import { useContext } from "react";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { auth } from "../../init/index";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  async function Signin(email, password) {
    return await createUserWithEmailAndPassword(auth, email, password);
  }
  async function Login(email, password) {
    return await signInWithEmailAndPassword(auth, email, password);
  }
  async function Logout(){
    return await signOut(auth);
  }
  useEffect(() => {
    const subscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        console.log(currentUser);
        setUser(currentUser);
      } else {
        setUser(currentUser)
        console.log("No user");
      }
    });
  }, []);
  const value = {
    user,
    auth,
    Login,
    Signin,
    Logout,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export function useUserContext() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
}
